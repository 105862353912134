import axios from 'axios'

// create an axios instance
const service = axios.create({
    baseURL: 'http://39.106.7.149:8000', // url = base url + request url
    // baseURL: process.env.VUE_APP_API, 
    timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['token'] = sessionStorage.getItem('token')
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        // console.log('cj',response);
        const res = response.data
        return res
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

export default service
