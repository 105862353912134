<template>
	<div ref="element" class="home">
		<div class="head">
			<i :style="isMobile ? { fontSize: '18px' } : {}">全国服务众包信息平台</i>
		</div>


		<div class="main">
			<div class="tab-head"
				:style="isMobile ? { display: 'flex', flexDirection: 'column', height: '480px',lineHeight:'35px'} : {}">

				<div class="tab-head-left">众包分类：</div>
				<div class="tab-head-right"  :style="isMobile ? { display: 'flex', flexDirection: 'column' } : {}">

					<div class="tab-1" :class="{ 'cTab': tabName == '全部' }" @click="clickTab('全部', $event)">全部</div>
					<div class="tab-1"  v-for="(item, index) in tabList"
						:class="{ 'cTab': item == tabName }" :key="index" @click="clickTab(item, $event)">{{ item }}
					</div>


				</div>
			</div>
			<div class="tab-content">
				<div class="tab-content-title">
					平台已收录
				</div>
				<div class="tab-content-card">
					<div class="card-item" v-for="(item, index) in SlList" :key="index">
						<div class="item-title">
							<div class="vertical"></div>
							{{ item.name }}
						</div>
						<div class="item-content">
							<div class="item-content-1" v-for="(ite, index) in item.list" :key="index"
								@click="clickAddress(ite.url)">
								<img :src="ite.logo" alt="">
								<p>{{ ite.name }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="guanggaolist.length" class="side">
			<div v-for="(item,index) in guanggaolist" :key="index">
				<img :src="item.logo_image" alt="" v-if="item.logo_image" @click="clickTp1(item.network_addr)">
				<div class="del" @click="del(index)">
					<i class="el-icon-error"></i>
				</div>
			</div>

			<!-- <div v-show="gg2">
				<img :src="ggTp2.image" alt="" v-if="ggTp2.image" @click="clickTp2()">
				<div class="del" @click="del2()">
					<i class="el-icon-error"></i>
				</div>
			</div> -->
		</div>

		<div class="backTop" @click="clickTop" v-show="fShow2">
			<img :src="ggTp2" alt="">
		</div>


	</div>
</template>

<script>
	import {
		getAll,
		getLb,
		getGgLb
	} from "../api/all.js"
	export default {
		data() {
			return {
				tabList: [],
				tabName: '全部',
				fwNum: 0,
				fShow2: false,
				fShow: false,
				SlList: [],
				ggTp1: {},
				ggTp2: {},
				gg1: true,
				gg2: true,
				isMobile: null,
				guanggaolist: []
			}
		},
		created() {
			// const userAgent = navigator.userAgent || navigator.vendor || window.opera;

			// if (/android|iPad|iPhone|iPod/.test(userAgent) || (navigator.userAgent.indexOf('Windows Phone') > -1)) {
			// 	this.isMobile = true;
			// } else {
			// 	this.isMobile = false;
			// }
			this.isMobile = this.isMobiles()
		},
		async mounted() {
			await this.getTab()
			await this.getSl()
			await this.getGg()


		},
		methods: {
			isMobiles() {
				const userAgent = navigator.userAgent || navigator.vendor || window.opera;
				return /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent);
			},

			clickTab(item, o) {
				console.log(item, o)


				if (item == '全部') {
					getLb()
						.then(res => {
							console.log(res.data)
							this.SlList = res.data
						})
				} else {
					getLb({
							type: item
						})
						.then(res => {
							this.SlList = res.data
						})
				}

				this.tabName = o.target.innerText
			},
			clickAddress(add) {
				window.open(add, '_blank')
			},
			//滚动监听
			handleScroll() {

				fwNum = document.documentElement.scrollTop

				let mzzb_home_page = localStorage.getItem("mzzb_home_page");

				if (this.fwNum > 2500 && !mzzb_home_page) {
					this.fShow = true
					this.fShow2 = true
				} else if (fwNum > 2500 && mzzb_home_page) {
					this.fShow = false
					this.fShow2 = true
				} else {
					this.fShow = false
					this.fShow2 = false
				}
			},
			//点击返回顶部
			clickTop() {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				})
			},
			getTab() {
				getAll()
					.then(res => {
						this.tabList = res.data
					})
			},
			getSl() {
				getLb()
					.then(res => {
						this.SlList = res.data
					})
			},
			async getGg() {
				await getGgLb()
					.then(res => {
						if (res.data) {
							this.guanggaolist = res.data
						}
						// this.$set(this.ggTp1, 'image', res.data[0].logo_image)
						// this.$set(this.ggTp2, 'image', res.data[1].logo_image)
						// this.$set(this.ggTp1, 'url', res.data[0].network_addr)
						// this.$set(this.ggTp2, 'url', res.data[1].network_addr)
						// this.ggTp1.image = res.data[0].logo_image
						// this.ggTp1.url = res.data[0].network_addr
						// this.ggTp2.image = res.data[1].logo_image
						// this.ggTp2.url = res.data[1].network_addr
						// this.ggTp1 = res.data[0]
						// this.ggTp2 = res.data[1]

						// console.log(this.ggTp1,this.ggTp2)
					})
			},
			clickTp1(url) {
				window.open(url)
			},
			clickTp2() {
				window.open(this.ggTp2.url, '_blank')
			},
			del(index) {
				// 检查索引是否在数组范围内
				if (index >= 0 && index < this.guanggaolist.length) {
					this.guanggaolist.splice(index, 1); // 删除指定索引的元素
				} else {

				}
			},
			del2() {
				this.gg2 = false
			},
			fhTp(obj) {
				return obj.logo_image
			}
		}
	}
</script>

<style scoped lang="less">
	.home {
		overflow-y: hidden;
		min-height: 100vh;
		background-color: #f5f5f5;
		padding: 0 10%;
		box-sizing: border-box;
	}

	.head {
		height: 273px;
		background-image: url('../../public/home/headBack.png');
		background-repeat: no-repeat;
		background-size: 100% 370px;
		background-position: 0 -70px;
		overflow: hidden;
		padding: 0 5%;
		font-size: 40px;
		line-height: 273px;
		color: #fff;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.main {
		.tab-head {
			display: flex;
			height: 75px;
			line-height: 75px;
			background-color: #fff;
			box-sizing: border-box;
			padding: 0 10px;
			border-bottom: 1px solid #E7E7E7;

			&-left {
				// flex-shrink: 0;
				font-size: 22px;

			}

			&-right {
				display: flex;
				// overflow-x: auto;
				font-size: 18px;
				color: #464646;

				>div {
					margin: 0 15px;
					// flex-shrink: 0;

					cursor: pointer;
				}
			}

			.cTab {
				
				border-bottom: 2px solid #0331A0;
				color: #0331A0;
			}
		}

		.tab-content {
			box-sizing: border-box;
			padding: 10px;
			width: 100%;
			background-color: #fff;

			&-title {
				font-size: 18px;
				margin-bottom: 20px;
			}

			&-card {
				.card-item {
					margin-bottom: 30px;
				}

				.item-title {
					display: flex;
					align-items: center;
					font-size: 22px;
					color: #3D3D3D;
					font-weight: 500;
					margin-bottom: 20px;

					.vertical {
						border: 2px solid #013299;
						height: 18px;
						border-radius: 2px;
						margin: 0 15px;
					}
				}

				.item-content {
					display: flex;
					flex-wrap: wrap;
					margin-left: 15px;

					>div {
						width: 199px;
						height: 68px;
						border-radius: 10px;
						display: flex;
						margin-bottom: 20px;
						flex-shrink: 0;
						border: 1px solid #DBDBDB;
						align-items: center;
						font-size: 18px;
						margin-right: 30px;
						box-shadow: 1px 1px 5px 2px #DBDBDB;
						cursor: pointer;
						background-color: #FBFCFD;
					}

					.item-content-1:hover {
						transition: all .25s ease-in;
						transform: translateY(-15px)
					}

					img {
						width: 36px;
						height: 36px;
						margin: 0 30px;
					}
				}
			}
		}
	}

	.demo-tabs>.el-tabs__content {
		padding: 32px;
		color: #6b778c;
		font-size: 32px;
		font-weight: 600;
	}

	.backTop {
		position: fixed;
		right: 80px;
		top: 75%;
		z-index: 10;

		img {
			width: 40px;
			height: 40px;
		}
	}

	.side {
		position: fixed;
		right: 15px;
		top: 30%;
		display: flex;
		flex-direction: column;
		z-index: 5;
		cursor: pointer;

		>div {
			position: relative;
		}

		.del {
			position: absolute;
			top: -8px;
			right: -8px;
		}

		i {
			width: 16px;
			height: 16px;
		}

		img {
			width: 187px;
			height: 117px;
			margin-bottom: 30px;
			border-radius: 10px;
		}
	}
</style>