import http from './http'
//所有分类
export function getAll(){
    return http({
        url:'/get_all_type',
        method:'post'
    })
}
//获取收录列表
export function getLb(data){
    return http({
        url:'/get_services_pt',
        method:'post',
        data
    })
}
//获取广告列表
export function getGgLb(){
    return http({
        url:'/get_ad',
        method:'post'
    })
}
//收录申请
export function slsq(data){
    return http({
        url:'/sq_sl',
        method:'post',
        data
    })
}
//收录验证
export function slyz(data){ 
    return http({
        url:'/pt_yz',
        method:'post',
        data
    })
}
