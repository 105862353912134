<template>
  <div id="app">
    <div class="" style="height: 81px;width: 100%;"></div>
    <el-container>

      <el-header height="81px">

        <div class="head-left"> 
          <router-link to="/">
            <img src="/home/logo.png" alt="">
          </router-link>
        </div>    

        <div class="head-right">
          <router-link to="/about">
            <p class="sq">收录申请</p>
          </router-link>
        </div>


      </el-header>
      <el-main>
        <router-view />
      </el-main>
    </el-container>

    <div class="foot">
      <div>
        Copyright © 2024 版权所有 <a href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank">蜀ICP备2021027962号</a>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name:'HomeView',
  data() {
    return {

    }
  },
  methods: {
    clickHome(){
      this.$router.push({name:'home'})
    }
  },
}
</script>

<style lang="less" scoped>
.el-header {
  /* background-color: pink; */
  // height: 81px !important;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  background-color: #fff;
  top: 0;
  z-index: 99;

  .head-left {
    display: flex;
    font-size: 22px;
    color: #00349A;
    font-weight: bold;
    align-items: center;
  }

  img {
    width: 156px;
    height: 70px;
  }


  .head-right {
    height: 100%;
    align-items: center;
    display: flex;
  }

  .sq {
    width: 108px;
    height: 37px;
    background-color: #0331A0;
    color: #fff;
    line-height: 37px;
    text-align: center;
    border-radius: 19px;
  }
}

.el-main {
  padding: 0;
}



.foot {
  width: 100%;
  height: 49px;
  // background: linear-gradient(180deg, #FCC605 0%, #F58019 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  // color: #FFFFFF;
}
</style>
